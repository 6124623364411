import {IImageGql} from '../../types/app.types';
import {media} from '@wix/sdk';

export class ImageModel {
  public url: string;
  public width: number;
  public height: number;
  /**
   * @deprecated
   */
  public mediaType?: string;

  constructor(params: ImageModel) {
    this.url = params?.url;
    this.width = params?.width;
    this.height = params?.height;
    this.mediaType = params?.mediaType;
  }

  public static fromGQL(image: IImageGql) {
    return new ImageModel({
      url: image?.url,
      width: image?.width,
      height: image?.height,
      mediaType: image?.mediaType,
    });
  }

  public static fromSDK(imageUrl: string) {
    const image = media.getImageUrl(imageUrl);
    return new ImageModel({
      url: image.filename,
      width: image?.width,
      height: image?.height,
    });
  }
}
